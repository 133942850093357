<template>
  <div class="error-page">
    <div class="fix-txt">
      Make<br />
      Greatness<br />
      <span>e motion global</span>
    </div>
    <div class="error-txt">
      <div class="title">
        <strong>404</strong>
        <p>찾을 수 없는 페이지 입니다.</p>
      </div>
      <div class="dec">
        <p class="ko">요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨어요 :)</p>
        <p class="en">We can't seem to find the page you're looking for.</p>
      </div>
      <div class="link-box">
        <router-link to="/" class="link-txt">
          <span>메인으로</span>
        </router-link>
        <a href="#" class="link-txt" @click.prevent="$router.go(-1)">
          <span>이전 페이지</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "error-404",
    metaInfo() {
      return {
        title: "404",
        titleTemplate: "%s | e · motion"
      };
    }
  };
</script>
<style scoped lang="scss">
  .error-page {
    @include globalWidth();
    padding: 60px 30px;
    min-height: 100vh;
    .error-txt {
      margin-top: 40px;
      .title {
        strong {
          @include montserrat();
          font-size: 100px;
          line-height: 50px;
          font-weight: 900;
          letter-spacing: 0.03em;
        }
        p {
          margin-top: 40px;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -0.05em;
        }
      }
      .dec {
        margin-top: 20px;
        &:before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          margin-bottom: 10px;
          border-radius: 100%;
          background: $red;
        }
        .ko {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.03em;
        }
        .en {
          @include montserrat();
          font-size: 12px;
          line-height: 16px;
        }
      }
      .link-box {
        margin: 87px 0 0 -10px;
        .link-txt {
          display: inline-flex;
          padding: 10px 10px;
          font-size: 14px;
          line-height: 21px;
          span {
            display: block;
            color: #000;
            border-bottom: 1px solid #000;
          }
        }
      }
    }
    .fix-txt {
      @include montserrat();
      font-size: 20px;
      line-height: 24px;
      font-weight: 900;
      span {
        color: $red;
      }
      &:before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        margin: 0 0 0 0;
        border-radius: 100%;
        background: $red;
      }
    }
  }
</style>
